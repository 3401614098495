
const getIndex = () => import('~/static/index.json').then( m => m.default || m )
import VueScrollTo from "vue-scrollto"

import waves from '~/static/waves.svg?raw'
export default {
  head() {
    return {
      title: this.index.data[0].metatitle
    }
  },
  data () {
    return {
      waves,
      showForm: false,
      // vue scroll
      options: {
        offset: -60
      }
    }
  },
  methods: {
    // show wizard
    startForm() {
      this.showForm = !this.showForm
      var cancelScroll = VueScrollTo.scrollTo("#mainForm", 300, this.options)
      if (!this.showForm) {
        var cancelScroll = VueScrollTo.scrollTo("#__layout", 300, this.options)
      }
    }
  },
  async asyncData ({ req }) {
    const index = await getIndex()
    return { index }
  },
  mounted () {

  },
  created () {
  },
}
